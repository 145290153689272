<template>
    <main>
        <section class="login">
            <div class="logo">
                <img src="../assets/logo_black.svg"  alt="" />
            </div>
            <div class="login_box">
                <!-- <div class="header_checkbox">
                    <label class="c_checkbox">
                        <input type="radio" checked="checked" name="radio" value="Pure Player" v-model="loginType">
                        <span class="checkmark">{{ 'Pure Player' }}</span>
                    </label>
                    <label class="c_checkbox">
                        <input type="radio" name="radio" value="Brick & Click" v-model="loginType">
                        <span class="checkmark">Brick & Click</span>
                    </label>
                </div> -->
                <div class="header">
                    <h1 class="head">Sign In to Duracell Perfect e-store</h1>
                    <!-- <p class="subtitle text-uppercase">( {{loginType}} )</p> -->
                </div>
                <form @submit="loginBtnClick">
                    <div class="field">
                        <!-- <div class="field_box active error"> -->
                        <div class="field_box">
                            <label for="email">Email Address</label>
                            <input type="email" ref="email" placeholder="someone@gmail.com">
                        </div>
                        <!-- <p class="error_text show">Email fail! Please type in your correct email address</p> -->
                        <p class="error_text">Email fail! Please type in your correct email address</p>
                    </div>
                    <div class="field">
                        <div class="field_box">
                            <label for="email">Password</label>
                            <input type="password" ref="password" placeholder="**********">
                        </div>
                        <p class="error_text">Email fail! Please type in your correct email address</p>
                    </div>
                    <p style="color: #E34E6B;" v-if="authError"> There was an error logging in to your account. </p>
                    <button type="submit" class="submit_btn">Sign In</button>
                    <router-link
                      class="forgot_link"
                      to="/forgot"
                      v-if="false"
                    > Forgot password? </router-link>
                    <!-- <a href="./reset-pass.html" class="forgot_link">Forgot password?</a> -->
                    <a href="#" class="back_btn hide"><span></span> Back to Sign In</a>
                </form>
            </div>
        </section>
    </main>
</template>

<script>
import { mapActions } from "vuex";
import $ from 'jquery';
export default {
  name: '',
  components: {},
  computed: {},
  data() {
    return {
      // email: 'demo@example.com',
      // password: '123456',
      email: '',
      password: '',
      authError: null,
      tryingToLogIn: false,
      loginType: 'Pure Player',
    }
  },
  mounted() {
    $('.field_box > *')
    .focus(function() {
        $(this).closest('.field_box').addClass('active');
    })
    .blur(function() {
        $(this).closest('.field_box').removeClass('active');
    });
  },
  watch: {
    name() {}
  },
  methods: {
    ...mapActions(["logIn"]),
    loginBtnClick(e) {
      e.preventDefault();
      this.tryingToLogIn = true
      this.authError = null
      return this.logIn({
        email: this.$refs.email.value,
        password: this.$refs.password.value,
      })
        .then(() => {
          this.tryingToLogIn = false
          // Redirect to the originally requested page, or to the home page
          this.$router.push(this.$route.query.redirectFrom || { name: 'Carat' })
        })
        .catch((error) => {
          this.tryingToLogIn = false
          this.authError = error
        })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
