<template>
    <header class="pp__header">
        <nav class="top-bar">
            <div class="nav-left">
                <div class="hmenu-open-btn" @click="$document.body.classList.add('submenu-open')">
                    <img src="../assets/hm-icon.svg" alt="">
                    <!-- <img src="../assets/hm-icon.svg" alt=""> -->
                </div>
                <div id="hmenu-canvas" class="hmenu">
                <!-- <div id="hmenu-canvas" class="hmenu"> -->
                    <div class="hmenu-canvas-background">
                        <!-- <i class="hmenu-close-icon fa fa-close"></i> -->
                        <svg class="hmenu-close-icon" @click="$document.body.classList.remove('submenu-open')" xmlns="http://www.w3.org/2000/svg" width="29.648" height="29.648" viewBox="0 0 29.648 29.648">
                            <g id="Group_964" data-name="Group 964" transform="translate(-4117.748 15950.588)">
                            <path id="Path_361" data-name="Path 361" d="M1881.808-13065.527l27.527,27.527" transform="translate(2237 -2884)" fill="none" stroke="#fff" stroke-width="3"/>
                            <path id="Path_362" data-name="Path 362" d="M1909.335-13065.527,1881.808-13038" transform="translate(2237 -2884)" fill="none" stroke="#fff" stroke-width="3"/>
                            </g>
                        </svg>
                    </div>
                    <div class="hmenu-content" v-if="currentUser">
                        <div class="header">
                            <a href="#">
                                <h3> <img src="../assets/user.svg" alt=""> Hello, {{ currentUser ? capitalize(currentUser.first_name) + ' ' + capitalize(currentUser.last_name) : 'Sign In'}} </h3>
                            </a>
                        </div>
                        <ul>
                            <li class="head">Trending</li>
                            <li> <a href="#">Best Sellers</a> </li>
                            <li><a href="#">New Releases</a></li>
                            <li><a href="#">Movers and Shakers</a></li>
                            <li class="hmenu-separator"></li>
                            <div class="abc" v-for="(item, i) in categories" :key="i">
                                <li class="head" > {{ item.name }} </li>
                                <li>
                                    <a href="#" v-for="(child, j) in item.children" :key="j">{{ child.name }}</a>
                                </li>
                                <li class="hmenu-separator"></li>
                            </div>
                            <li class="head">Help</li>
                            <li><a href="#">Your Account</a></li>
                            <li><a href="#">Customer Service</a></li>
                            <li><a href="#">Sign Out</a></li>
                        </ul>
                    </div>
                </div>
                <div class="logo">
                    <router-link
                        class="forgot_link"
                        to="/"
                    > <img :src="logoLoad" alt="Logo"></router-link>
                </div>
            </div>
            <div class="nav-fill">
                <form accept-charset="utf-8" @submit.prevent="searchFormSubmit" class="nav-searchbar" method="GET" name="site-search" role="search">
                    <div class="nav-left">
                        <div class="catagory-dropdown">{{ $store.state.selectedCategory.name }}</div>
                        <select class="catagory-dropdown-select" name="category" v-model="selectedCategory">
                            <!-- <option value="1">All</option> -->
                            <option v-for="(item, i) in cats" :key="i" :value="item.id">{{item.name}}</option>
                        </select>
                    </div>
                    <div class="nav-fill">
                        <div class="nav-search-field ">
                            <input type="text" name="search" class="nav-input" v-model="search">
                        </div>
                    </div>
                    <div class="nav-right">
                        <div class="nav-search-submit nav-sprite">
                            <button type="submit" class="nav-input" tabindex="0">
                                <img src="../assets/search.svg" alt="">
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="nav-right">
                <ul class="nav-links">
                    <li>
                        <div class="dropdown country-dropdown">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img src="../assets/flag-en.svg">
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#"><img src="../assets/flag-en.svg"> English </a>
                            <a class="dropdown-item" href="#"><img src="../assets/flag-fr.svg"> French </a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="dropdown account-dropdown">
                            <div class="dropdown-toggle" type="button" id="dropdownMenuButtonForAccount" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div class="show-desktop">
                                    <span>Hello, {{ currentUser ? capitalize(currentUser.first_name) : 'Sign In'}}</span> Account & Info 
                                </div>
                                <div class="show-mobile">
                                    <i class="fa fa-user"></i>
                                </div>
                            </div>
                            <div class="dropdown-menu siginin" aria-labelledby="dropdownMenuButtonForAccount">
                            <div class="siginin_box" v-if="!currentUser">
                                <a href="#" class="signin_btn">Sigin In</a>
                                <a href="#" class="new_customer">New customer? <span>Start here.</span></a>
                            </div>
                            <div class="siginin_box" v-if="currentUser">
                                <a class="signin_btn"  @click="logOutBtnClick">Logout</a>
                                <a class="new_customer">{{capitalize(currentUser.first_name)}} {{capitalize(currentUser.last_name)}}</a>
                            </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="#">
                            <div class="show-mobile">
                                <i class="fa fa-eye"></i>
                            </div>
                            <div class="show-desktop">
                                <span>Returns &</span>Orders
                            </div>
                        </a>
                    </li>
                    <li>
                        <router-link :to="{name: 'Cart'}" class="nav__cart">
                            <div id="nav-cart-count-container" class="cart__icon">
                                <span id="nav-cart-count" aria-hidden="true" class="nav-cart-count">{{$store.getters.cartItems.length}}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="29.508" height="25.23" viewBox="0 0 29.508 25.23">
                                    <g id="Icon_feather-shopping-cart" data-name="Icon feather-shopping-cart" transform="translate(-0.924 -3.77)">
                                    <path id="Path_46" data-name="Path 46" d="M14.524,31.262A1.262,1.262,0,1,1,13.262,30,1.262,1.262,0,0,1,14.524,31.262Z" transform="translate(-1.667 -4.524)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <path id="Path_47" data-name="Path 47" d="M31.024,31.262A1.262,1.262,0,1,1,29.762,30,1.262,1.262,0,0,1,31.024,31.262Z" transform="translate(-4.286 -4.524)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <path id="Path_48" data-name="Path 48" d="M1.924,4.77H7.253L9.93,18.4a2.524,2.524,0,0,0,2.524,2.032H24.719A2.524,2.524,0,0,0,27.243,18.4L29.262,7.81h0" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    </g>
                                </svg>
                            </div>
                            <strong class="show-desktop">Cart</strong>
                        </router-link>
                    </li>
                </ul>
            </div>
        </nav>
        <nav class="menulist" v-if="categories">
            <Slick ref="slick" class="menulist-strip" :options="slickOptions" v-if="categories.length">
                <a v-for="(item, i) in cats" :key="i"  @click="gotoSearch(item)" :class="item.id == $store.state.selectedCategory.id ? 'active': ''" >{{item.name}}</a>
            </Slick>
        </nav>
        <section class="brandstore_banner" v-if="$route.name == 'BrandStore'" :style="{backgroundSize: 'cover', backgroundImage: `url(${require('../assets/brandstore_banner.png')})`}">
        </section>
        <nav class="sub-menu-product" v-if="($route.name == 'ProductDetail' || $route.name == 'BrandStore') && getValueFromObj(initialCaratSetting, 'iEBrandMenu')">
            <div class="menulist-strip">
                <h3 class="heading">Duracell products</h3>
                <router-link :to="`/brand-store/Alkaline batteries`" :class="checkActive('Alkaline batteries')"><span><img src="../assets/cell1.png" alt=""></span> Alkaline batteries</router-link>
                <router-link :to="`/brand-store/Duracell Optimum`" :class="checkActive('Duracell Optimum')"><span><img src="../assets/cell2.png" alt=""></span> Duracell Optimum</router-link>
                <router-link :to="`/brand-store/Specialties & Coin batteries`" :class="checkActive('Specialties & Coin batteries')"><span><img src="../assets/cell3.png" alt=""></span> Specialties & Coin batteries</router-link>
                <router-link :to="`/brand-store/Power banks`" :class="checkActive('Power banks')"><span><img src="../assets/cell4.png" alt=""></span> Power banks</router-link>
                <router-link :to="`/brand-store/Rechargeable & specialty`" :class="checkActive('Rechargeable & specialty')"><span><img src="../assets/cell5.png" alt=""></span> Rechargeable & specialty</router-link>
            </div>
        </nav>
        <nav class="sub-menu hide">
            <div class="menulist-strip">
                <a href="#" class="active">Electronics</a>
                <a href="#">Accessories & Supplies</a>
                <a href="#">Camera & Photo</a>
                <a href="#">Car & Vehicle Electronics</a>
                <a href="#">Computers & Accessories</a>
                <a href="#">Computers & Accessories</a>
                <a href="#">Camera & Photo</a>
            </div>
        </nav>
    </header>
</template>

<script>
import Slick from "vue-slick";
import $ from 'jquery';
import { authComputed, authMethods } from '../store/helpers'
import { find } from 'lodash'
export default {
  name: '',
  components: { Slick },
  computed: {
      ...authComputed,
      cats(){
          let newArr = [...this.categories];
          newArr.splice(1,1)
          return newArr
      },
      logoLoad() {
          return this.$store.state.initialCaratSettingValue.selectedStore.logo ?
            this.$store.state.initialCaratSettingValue.selectedStore.logo : this.$store.state.genralInfo.logo
      }
  },
  data() {
    return { 
        name: '',
        selectedCategory: 'null',
        search: '',
        selectedCategoryName: 'All',
        slickOptions: {
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            centerMode: false,
            variableWidth: true,
        },
    }
  },
  mounted() {
    this.submitFrontForm();
    $(".sub_menu").each(function( ) {
        var liParent = $(this); 
        var trigger = $(this).find(".sub_menu_trigger"); 
        var closeTrigger = $(this).find(".back_btn"); 
        closeTrigger.on("click" , liParent, function(){
            liParent.find(".sub_menu_content").toggleClass("show");
        });
        trigger.on("click" , liParent, function(){
            liParent.find(".sub_menu_content").toggleClass("show");
        });
    });
  },
  watch: {
    selectedCategory() {
        this.selectedCategoryName = find(this.categories, {"id": this.selectedCategory}).name
        this.$store.commit("SET_SELECTED_CATEGORY", find(this.categories, {"id": this.selectedCategory}))
    },
    categories() {
        this.selectedCategoryName = this.categories[0].name
        this.$store.commit("SET_SELECTED_CATEGORY", this.categories[0])
    }
  },
  methods: {
      ...authMethods,
      searchFormSubmit(e) {
          e.preventDefault();
          this.getKeywordBanner(this.search);
          this.$router.push({ path: '/search', query: { category: this.$store.state.selectedCategory.id, search: this.search } })
      },
      checkActive(name) {
          const {id} = this.$route.params;
          if(name == id) {
              return 'active'
          }
      },
      gotoSearch(item) {
        this.$router.push({ path: '/search', query: { category: item.id } });
        this.$store.commit("SET_SELECTED_CATEGORY", item);
      },
      logOutBtnClick() {
        //   this.logOut();
        //   this.$router.push({ name: 'Login' });
        //   location.reload();
          this.logOut().then(() => {
              this.$router.push({ name: 'Login' });
              window.localStorage.removeItem("vuex")
              location.reload();
          })
          
          
      }
  }
}
</script>

<style scoped lang="scss">
    .dropdown-item {
        cursor: not-allowed;
    }

    a {
        cursor: pointer;
    }
</style>
