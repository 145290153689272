import MainPage from "../views/MainPage.vue";
import Search from "../views/Search.vue";
import Search2 from "../views/Search2.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ProductDetail from "../views/ProductDetail.vue";
import BrandStore from "../views/BrandStore.vue";
import ProductDetailBrick from "../views/ProductDetailBrick.vue";
import CaratSelectionView from "../views/CaratSelectionView.vue";
import Cart from "../views/Cart.vue";
import Report from "../views/Report.vue";
import Shipping from "../views/Shipping.vue";
import FavoriteView from "../views/FavoriteView.vue";

import store from "../store";

export default [
  {
    path: "/",
    component: MainPage,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: "/",
        name: "LandingPage",
        component: () => lazyLoadView(import("../views/Home.vue")),
      },
      {
        path: "/search",
        name: "Search",
        component: Search,
      },
      {
        path: "/search-2",
        component: Search2,
      },
      {
        path: "/product-detail",
        component: ProductDetail,
        props: true
      },
      {
        path: "/product-detail/:id",
        name: 'ProductDetail',
        component: ProductDetail,
        props: true
      },
      {
        path: "/brand-store/:id",
        name: 'BrandStore',
        component: BrandStore,
        props: true
      },
      {
        path: "/product-detail-brick/:id",
        component: ProductDetailBrick,
        props: true
      },
      {
        path: "/cart",
        name: 'Cart',
        component: Cart,
      },
      {
        path: "/shipping",
        name: 'Shipping',
        component: Shipping,
      },
      {
        path: "/favourite",
        name: "Favourite",
        component: FavoriteView,
        meta: {
          authRequired: true,
        },
      },
    ],
  },
  {
    path: "/report",
    name: 'Report',
    component: Report,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["loggedIn"]) {
          next({ name: "Carat" });
        } else {
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["loggedIn"]) {
          next({ name: "Carat" });
        } else {
          next();
        }
      },
    },
  },
  {
    path: "/forgot",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/carat",
    name: "Carat",
    component: CaratSelectionView,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/404",
    name: "404",
    component: require("../views/_404.vue").default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  {
    path: "*",
    redirect: "404",
  },
];

function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require("../views/_loading.vue").default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require("../views/_timeout.vue").default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  });

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children);
    },
  });
}
