<template>
  <main>
    <section class="carat_selection">
      <div class="logo">
        <img src="../../assets/logo_black.svg"  alt="" />
      </div>
      <h2 class="title">Set up your Perfect account</h2>
      <p class="desc">
        Please fill-in these information to set up your CARATS control panel.
        Before we get started, enter your E-Sell-Out figures from today.
      </p>
      <hr class="line" />
      <div class="store">
        <h2 class="title">Choose your store type mode</h2>
        <p class="desc">
          Choose between these two types of stores before signing in.
        </p>
        <div class="a">
          <!-- <label class="cc_radio" v-if="$store.state.currentUser.stores && $store.state.currentUser.stores.length > 0"> -->
          <label class="cc_radio" v-if="storeList.length > 0">
            <input type="radio" value="select" name="store_mode_selection" :checked="storeMode == 'select'" @change="changeStoreMode('select')"/>
            <span class="checkmark"></span>
            <span class="text">Select Existing Store</span>
          </label>
          <label class="cc_radio">
            <input type="radio" value="create" name="store_mode_selection" :checked="storeMode == 'create'" @change="changeStoreMode('create')"/>
            <span class="checkmark"></span>
            <span class="text">Create your own store</span>
          </label>
        </div>
      </div>
      <div class="store-selection">
        <!-- <div class="store-selection-parent" v-if="$store.state.currentUser.stores && $store.state.currentUser.stores.length > 0"> -->
        <div class="store-selection-parent">
          <div class="selection" v-if="storeMode == 'select'">
            <!-- <h2 class="selection_title" >You Don't have any store</h2> -->
            <div class="selection_box">
              <div class="dropdown">
                  <button class="dropdown-toggle" type="button" id="storeDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{ $store.state.initialCaratSettingValue.selectedStore.name }}
                    <!-- Default Store -->
                  </button>
                  <div class="dropdown-menu" aria-labelledby="storeDropdown">
                    <!-- <a class="dropdown-item" @click="changeChannel(item)" v-for="(item, i) in $store.state.channel.channelList" :key="i">{{item.code}}</a> -->
                    <a class="dropdown-item" 
                      v-for="(item, i) in $store.state.currentUser.stores" 
                      @click="changeStore(item)"
                      :key="i">
                        <p class="item-name">{{item.name}}</p>
                        <img @click="openConfirm(item)" src="../../assets/delete.svg" alt="">
                    </a>
                  </div>
              </div>
              <div class="confirm-modal" v-if="confirmModal">
                <p>Are you sure want to delete ?</p>
                <div class="button_list">
                  <button class="submit_btn" @click="confirmModal = false">Cancel</button>
                  <button class="submit_btn" @click="deleteStoreApi(confirmId); confirmModal=false; reset();">Delete</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="quick-access"> 
          <button class="submit_btn" v-if="storeMode === 'select'" @click="submitForm">Quick access to {{ $store.state.initialCaratSettingValue.selectedStore.name }}</button>
        </div>
        <div class="create_form">
          <h2 class="title">{{ storeMode == 'create'? 'Create' : 'Update'}} your own Store <span v-if="false">(You don't have created any store yet..)</span></h2>
          <div class="field">
              <!-- <div class="field_box active error"> -->
              <div class="field_box">
                  <label for="email">Enter Name</label>
                  <input type="email" v-model="storeName" placeholder="Name...">
                  <!-- <p class="error_text">Email fail! Please type in your correct email address</p> -->
              </div>
              <p class="error_text" :class="error ? 'show': ''">Name is mandatory</p>
          </div>
          <div class="field">
              <h2 class="title">Upload your logo</h2>
              <ul class="logo-img">
                <li style="margin-bottom: 2rem;" v-if="storeMode == 'select'">
                  <h3>Exstisting Logo</h3>
                  <img :src="$store.state.initialCaratSettingValue.selectedStore.logo" class="img-responsive img-thumbnail" alt="image">
                </li>
                <li v-for="(item,i) in uploadedFiles" :key="i">
                  <img :src="item.url" class="img-responsive img-thumbnail" :alt="item.originalName">
                </li>
              </ul>
              <button class="submit_btn" v-if="isSuccess" @click="reset()">Upload Again</button>
              <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
                <div class="dropbox">
                  <input type="file" :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                    accept=".png, .jpg, .jpeg" class="input-file">
                    <p v-if="isInitial">
                      Drag file for Upload
                    </p>
                    <p v-if="isSaving">
                      Uploading {{ fileCount }} files...
                    </p>
                </div>
              </form>
              <p class="error_text" :class="mxLimitCross ? 'show': ''" >mxLimitCross</p>
              <div class="subpoints">
                    <ul>
                      <li>File must be in JPEG or PNG format</li>
                      <li>Max file weight is 5MB</li>
                      <li>For optimum look please use PNG logo with transparent background</li>
                    </ul>
                </div>
          </div>
        </div>
      </div>
      <hr class="line" />
      <div class="update-store-section" v-if="storeMode === 'create'">
              <div class="store" >
        <h2 class="title">Choose your store type</h2>
        <p class="desc">
          Choose between these two types of stores before signing in.
        </p>
        <div class="a">
          <label class="cc_radio">
            <input :disabled="storeMode == 'select'" type="radio" value="Pure Player" name="storeType" @change="changeStoreType('Pure Player')" :checked="$store.state.storeType == 'Pure Player'"/>
            <span class="checkmark"></span>
            <span class="text">Pure Player</span>
          </label>
          <label class="cc_radio">
            <input :disabled="storeMode == 'select'" type="radio" value="Brick & Click" name="storeType" @change="changeStoreType('Brick & Click')"  :checked="$store.state.storeType == 'Brick & Click'"/>
            <span class="checkmark"></span>
            <span class="text">Brick & Click</span>
          </label>
        </div>
      </div>
      <hr class="line" />
      <div class="channel_selection" v-if="$store.state.channel.channelList.length > 0">
        <h2 class="title">{{storeMode == 'create' ? 'Choose': ''}} Channel</h2>
        <div class="select_box">
          <div class="dropdown">
              <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :disabled="storeMode == 'select'">
                {{ $store.state.channel.selectedChannel.code }}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" @click="changeChannel(item)" v-for="(item, i) in $store.state.channel.channelList" :key="i">{{item.code}}</a>
              </div>
          </div>
        </div>
      </div>
      <hr class="line" v-if="$store.state.channel.channelList.length > 0"/>
      <div class="table">
        <div class="container-fluid body">
          <div class="main_item">
            <p>E-Sell-Out Today ($)</p>
            <div class="input">
              <input class="value_input" type="number" placeholder="113.500" v-model="eSellOutToday"/>
              <div class="next_btn">
                <div class="arrow"></div>
              </div>
            </div>
          </div>
          <div class="row heading d-none">
            <div class="col-md-3 text-left">Carats section</div>
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-6 text-left">Feature name</div>
                <div class="col-md-6">Current execution</div>
              </div>
            </div>
          </div>
          <CaratSelection
            :img="require('@/assets/carats/pink-carats.svg')"
            color="#D33496"
            name="Search"
          >
            <template v-slot:bodyData>
              <CaratSelectionList keyValue="iESponsoredProduct" name="Sponsored products" />
              <!-- <CaratSelectionList keyValue="iERescentlyPurchase" name="Recently purchased" /> -->
              <CaratSelectionList
                keyValue="iEBanner"
                name="Banners"
                :radioList="
                {
                  keyValue: 'rdBanner',
                  items: [ 'Battery Search', 'FMGC Category' ]
                }
                " />
              <CaratSelectionList keyValue="iERecords" name="Endorsement record" />
              <CaratSelectionList keyValue="iEVideoSearch" name="Video Sponsored Product" />
            </template>
          </CaratSelection>
          <CaratSelection
            :img="require('@/assets/carats/yellow-carats.svg')"
            color="#ED7001"
            name="Assortment"
          >
            <template v-slot:bodyData>
              <CaratSelectionList keyValue="iEEfficient" name="Efficient assortment" />
              <!-- <CaratSelectionList keyValue="iEHighlight" name="Highlight portfolio changes" />
              <CaratSelectionList keyValue="iERetailer" name="Import retailer assortment" /> -->
            </template>
          </CaratSelection>
          <CaratSelection
            :img="require('@/assets/carats/green-carats.svg')"
            color="#01943F"
            name="CONTENT"
          >
            <template v-slot:bodyData>
                <CaratSelectionList keyValue="iEMobileFirst" name="Mobile First primary image" />
                <CaratSelectionList keyValue="iEOptimized" name="Optimized Product Title" />
                <CaratSelectionList keyValue="iESecondaryImages" name="Secondary Images"
                :radioList="
                {
                  keyValue: 'rdSecondaryImages',
                  items: [ 'Minimum 5 images' ]
                }
                " />
                <CaratSelectionList keyValue="iEVideo" name="Video Content" />
                <CaratSelectionList keyValue="iEBulletPoints" name="Bullet points" />
                <CaratSelectionList keyValue="iEProductDesc" name="Product Description" />
                <CaratSelectionList keyValue="iERichContent" name="Rich Content" />

                <CaratSelectionList keyValue="iEProductVariation" name="Product Variations"
                :radioList="
                {
                  keyValue: 'productVariationStyle',
                  items: [ 'Size & Style + Thumbnails', 'Size & Style', 'Size' ]
                }
                " />
                <CaratSelectionList keyValue="iEBrandMenu" name="Brand Menu" />
            </template>
          </CaratSelection>

          <CaratSelection
            :img="require('@/assets/carats/blue-carats.svg')"
            color="#0086C3"
            name="rates & reviews"
          >
            <template v-slot:bodyData>
              <CaratSelectionList keyValue="iERate" name="rates & reviews"
              :radioList="
                {
                  keyValue: 'rdRate',
                  items: [ 'Superior to 50 Reviews at 4 stars' ]
                }
              " />
            </template>
          </CaratSelection>
          <CaratSelection
            :img="require('@/assets/carats/red-carats.svg')"
            color="#DC0531"
            name="ACTIVE X-SELLING"
          >
            <template v-slot:bodyData>
              <CaratSelectionList keyValue="iELightBox" name="Light box" />
              <CaratSelectionList keyValue="iEFrequentlyBought" name="Frequently bought together" />
              <!-- <CaratSelectionList keyValue="iEPremium" name="Premium line rule" /> -->
              <CaratSelectionList keyValue="iEVirtual" name="Virtual bundle" />
              <CaratSelectionList keyValue="iECheckout" name="Check-out reminder" />
            </template>
          </CaratSelection>

        </div>
        <div class="button_list">
          <!-- <button class="submit_btn save_btn" @click="saveStore">Save</button> -->
          <button class="submit_btn" @click="submitForm">{{ storeMode == 'create' ? 'Save' : 'Update'}} &amp; Enter</button>
        </div>
      </div>
      </div>


      <div class="footer">
        <back-btn />
        <div class="logo">
          <img src="../../assets/logo_black.svg"  alt="" />
        </div>
        <div class="copyright">
          <ul>
            <li><a href="#">Conditions of Use</a></li>
            <li><a href="#">Privacy Notice</a></li>
          </ul>
        </div>
      </div>
    </section>
  </main>
</template>

<script>

// https://staging.duracell-perfect-store.com/api/customer/get

// https://staging.duracell-perfect-store.com/api/customer/get

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
import { authMethods } from '@/store/helpers';
import CaratSelection from './CaratSelection';
import CaratSelectionList from './CaratSelectionList';
import axios  from "axios";
import { upload } from './file-upload'
import { mapState } from 'vuex';
// import { find } from "lodash";
export default {
  name: "",
  components: {CaratSelectionList, CaratSelection},
  computed: {
    ...mapState(['currentUser']),
    storeMode() {
      return this.$store.state.initialCaratSettingValue.storeMode
    },
    storeList() {
      return this.$store.state.currentUser.stores
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    }
  },
  data() {
    return {
      name: "",
      pageType: "Pure Player",
      createStore: true,
      eSellOutToday: this.$store.state.carats.eSellOutToday,
      uploadedFiles: [],
      imageFormData: null,
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'photos',
      mxLimitCross: false,
      error: false,
      storeName: '',
      confirmModal: false,
      confirmId: null
    };
  },
  mounted() {
    if( this.$store.state.currentUser.stores.length > 0 ) { 
      this.setStoreMode('select') 
      this.changeStore(this.$store.state.currentUser.stores[0])
    }
    else { 
      this.setStoreMode('create') 
    }
    this.reset();
    this.setInitialSettings();
  },
  watch: {
    pageType() {
      this.$store.commit("SET_STORE_TYPE", this.pageType);
    },
    storeName() {
      // this.storeName.length == 0 ? this.error = true : this.error = false
      this.error = false
    },
    currentUser() {
      if( this.$store.state.currentUser.stores.length == 0 ) { 
        this.setStoreMode('create')
        this.storeName = ''
      }
    }
  },
  methods: {
    ...authMethods,
    openConfirm(item) {
      this.confirmModal = true;
      this.confirmId = item.id;
      // this.deleteStoreApi(item.id)
    },
    changeStore(item) {
      this.$store.commit('SET_SELECTED_STORE', item);
      this.getStoreData(item.id).then((data)=> {
        this.storeName = data.name
        this.changeStoreType(data.type == 1 ? 'Pure Player': 'Brick & Click')
        let objIndex = this.$store.state.channel.channelList.findIndex((obj => obj.pivot.channel_id == data.channel_id));  
        this.$store.commit("SET_SELECTED_CHANNEL", this.$store.state.channel.channelList[objIndex])
        this.$store.commit('SET_CARATS', { forKey: 'eSellOutToday', value: parseInt(data.sell_out_value) });
        this.eSellOutToday = data.sell_out_value;
      })
    },
    reset() {
        // reset form to initial state
        this.currentStatus = STATUS_INITIAL;
        this.uploadedFiles = [];
        this.uploadError = null;
    },
    save(formData) {
        // upload data to the server
      if(formData.getAll('photos')[0].size > 5000000 ) {
        this.mxLimitCross = true;
        return
      }
      this.currentStatus = STATUS_SAVING;
      this.imageFormData = formData;
      upload(formData)
        // .then(wait(1500)) // DEV ONLY: wait for 1.5s 
        .then(x => {
          this.uploadedFiles = [].concat(x);
          this.currentStatus = STATUS_SUCCESS;
          // console.log("VIVEK", );
        })
        .catch(err => {
          this.uploadError = err.response;
          this.currentStatus = STATUS_FAILED;
        });
    },
    filesChange(fieldName, fileList) {
      // handle file changes
      this.mxLimitCross = false;
      const formData = new FormData();
      if (!fileList.length) return;
      // append the files to FormData
      Array
        .from(Array(fileList.length).keys())
        .map(x => {
          formData.append(fieldName, fileList[x], fileList[x].name);
        });
      // save it
      this.save(formData);
    },
    changeChannel(item) {
      if (this.storeMode == 'select') { return; } 
      axios.defaults.headers.common['channel'] = `${item.code}`
      this.$store.commit("SET_SELECTED_CHANNEL", item)
    },
    changeStoreType(item) {
      this.$store.commit("SET_STORE_TYPE", item);
    },
    changeStoreMode(item) {
      // this.$store.commit("SET_STORE_TYPE", item);
      // this.storeMode = item;
      this.storeName = "";
      this.error = false;
      this.reset()
      this.setStoreMode(item);
      if(item =='select') {
        this.changeStore(this.$store.state.currentUser.stores[0])
      } else {
        this.eSellOutToday = 1000000;
        this.setInitialSettings();
      }
    },
    submitForm() {
      if(this.storeName == "") {
        this.error = true;
        window.scrollTo(0, 800);
        return;
      }
      
      this.$store.commit('SET_CARATS', { forKey: 'eSellOutToday', value: parseInt(this.eSellOutToday) });
      this.submitFrontForm();
      // this.$store.dispatch(" ");
      const formData = new FormData();
      formData.append('name', this.storeName);
      formData.append('type', this.$store.state.storeType == 'Pure Player' ? 1 : 2);
      formData.append('sell_out_value', this.eSellOutToday);
      // console.log("VIVEK", this.imageFormData, );
      if(this.imageFormData && this.imageFormData.has('photos'))
        formData.append('logo', this.imageFormData.getAll('photos')[0], this.imageFormData.getAll('photos')[0].name);
      if (this.storeMode == 'select') { 
        formData.append('store_id', this.$store.state.initialCaratSettingValue.selectedStore.id);
        this.updateStoreApi(formData)
        .then(()=> {
          this.storeName = "";
          this.$router.push(
            this.$route.query.redirectFrom || { name: "Search" }
          );
        }) 
      } else {
        formData.append('channel_id', this.$store.state.channel.selectedChannel.pivot.channel_id);
        formData.append('user_settings', JSON.stringify( this.$store.state.initialCaratSettingValue.settings));
        this.createStoreApi(formData)
        .then(()=> {
          this.storeName = "";
          this.$router.push(
            this.$route.query.redirectFrom || { name: "Search" }
          );
        })
      }




      // let sendData = {
      //   name: this.storeName,
      //   channel_id: this.$store.state.channel.selectedChannel.pivot.channel_id,
      //   // logo: require('../../assets/list.png'),
      //   user_settings: [this.$store.state.initialCaratSettingValue.settings[0]]
      // }

      // var a = this.$store.state.caratshome;
      // for (const [key, value] of Object.entries(a)) {
      //   if(value == 2) {
      //     this.$store.commit('SET_CARATS', { forKey: key, value:  true});
      //     this.$store.commit('SET_PREV_STATE', { forKey: key, value:  true});
      //   }
      // }
      
      
    },
  },
};
</script>

<style lang="scss"> </style>
