<template>
  <div>
    <Loader v-if="$store.state.showLoader" />
    <SearchPP v-if="$store.state.storeType == 'Pure Player'" />
    <SearchBrick v-if="$store.state.storeType !== 'Pure Player'" />
  </div>
</template>

<script>
import SearchBrick from "./Search-brick";
import SearchPP from "./Search-pp";
import Loader from "../components/Loader.vue";
export default {
  name: 'Search',
  components: {
    SearchBrick,
    SearchPP,
    Loader
  }
}
</script>